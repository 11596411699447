<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/applyfor/banner.png" alt="" />
    </div>
    <div class="container">
      <div class="box">
        <div class="one_title">申报条件</div>
        <div class="content">
          <div class="ask_box">
            <div class="ask_title">
              <div class="square"></div>
              <span>服务对象要求:</span>
            </div>
            <ul>
              <li>
                <div class="num">01</div>
                <span>设立登记在本省行政区域内的</span>
              </li>
              <li>
                <div class="num">04</div>
                <span>已依法取得相关行业行政许可或行业资质的</span>
              </li>
              <li>
                <div class="num">02</div>
                <span
                  >取得法人资格，依法独立享有民事权利和承担民事法律责任的</span
                >
              </li>
              <li>
                <div class="num">05</div>
                <span
                  >依法办理纳税申报、缴纳社会保险，具有健全规章制度且运行良好的</span
                >
              </li>
              <li>
                <div class="num">03</div>
                <p>
                  <span>符合《关于印发中小企业划型标准规定的通知》</span>
                  <span>(工信部联企业〔2011〕300号)规定的中小微企业标准的</span>
                </p>
              </li>
              <li>
                <div class="num">06</div>
                <p>
                  <span>已开展正常生产经营活动，且已经实现收入的</span>
                  <span
                    >(设立登记日至申请日三个月内的中小微企业，作出书面承诺)</span
                  >
                </p>
              </li>
            </ul>
          </div>
          <div class="ask_box service">
            <div class="ask_title">
              <div class="square"></div>
              <span
                >有下列情形的中小微企业，不纳入服务范围或中止政府为其购买服务事项</span
              >
            </div>
            <div class="service_list">
              <div class="service_item">
                <div class="left_num">01</div>
                <div class="right_service_content">
                  <p>
                    <span>被列入“信用中国”网站中失信被执行人、</span>
                    <span>重大税收违法案件当事人名单的</span>
                  </p>
                </div>
              </div>
              <div class="service_item">
                <div class="left_num">03</div>
                <div class="right_service_content">
                  <p>
                    <span
                      >被列入“中国政府采购网”政府采购严重违法失信行为记录名单的</span
                    >
                  </p>
                </div>
              </div>
              <div class="service_item">
                <div class="left_num">02</div>
                <div class="right_service_content">
                  <p>
                    <span>被列入“国家企业信用公示系统”中经营异常名录、 </span>
                    <span>严重违法失信名单的</span>
                  </p>
                </div>
              </div>
              <div class="service_item">
                <div class="left_num">04</div>
                <div class="right_service_content">
                  <p>
                    <span>违反其他法律法规等情形的</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container container_bg">
      <div class="box">
        <div class="one_title">服务内容</div>
        <div class="content">
          <p>
            审核原始凭证、填制记账凭证、登记会计账簿、编制并对外提供财务会计报告，向税务机关提供税务资料。
          </p>
          <p>
            服务对象接受会计服务机构提供上述范围以外的其他服务，不属于购买代理记账服务范围。
          </p>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="box">
        <div class="one_title">申报流程</div>
        <div class="content">
          <div class="flow_img">
            <img src="../../assets/img/applyfor/flow.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="container container_bg">
      <div class="box">
        <div class="content">
          <div class="material_box">
            <div class="left_box">
              <div class="one_title">申报材料</div>
              <div class="material_item">
                （一）中小微企业申请财政购买代理记账服务申请表
                <a href="/html/申请表.docx" download>（附件下载）</a>
              </div>
              <div class="material_item">
                （二）近三个月会计报表、纳税申报表、完税证明及社保缴纳情况表
              </div>
              <div class="material_item">
                （三）法人身份证、营业执照及其他相关资质复印件
              </div>
              <div class="material_item">
                （四）设立登记日至申请日三个月内的中小微企业提供承诺书
                <a href="/html/承诺书.docx" download>（附件下载）</a>
              </div>

              <div class="apply_btn" @click="toApplyform">立即申请</div>
            </div>
            <div class="line"></div>
            <div class="right_box">
              <div class="img_box">
                <img src="../../assets/img/applyfor/material.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 审核通过结果公示 -->
    <div class="container">
      <div class="box">
        <div class="one_title">审核通过结果公示</div>
        <div class="content">
          <div class="gs_header">
            <div>企业名称</div>
            <div>申请时间</div>
            <div>公示时间</div>
          </div>
          <div class="swiper_box">
            <vue-seamless-scroll
              :data="publicEnterpriseList"
              :class-option="optionHover"
              class="eval_ul"
            >
              <div
                class="data_item swiper-slide"
                v-for="(item, index) in publicEnterpriseList"
                :key="index"
              >
                <div>{{ item.customerName }}</div>
                <div>{{ item.applyDate }}</div>
                <div>{{ item.publicDateStart }}</div>
              </div>
            </vue-seamless-scroll>
            <!-- <div class="swiper mySwiper">
              <div class="data_list swiper-wrapper">
                <div
                  class="data_item swiper-slide"
                  v-for="(item, index) in publicEnterpriseList"
                  :key="index"
                >
                  <div>{{ item.customerName }}</div>
                  <div>{{ item.applyDate }}</div>
                  <div>{{ item.publicDateStart }}</div>
                </div>
              </div>
            </div> -->
          </div>

          <div class="search_box">
            <div class="search_title">公示企业查询：</div>
            <div class="search_input">
              <input
                type="text"
                v-model="customerName"
                placeholder="请输入企业名称"
              />
              <div class="search_btn" @click="search">查 询</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="申请对象公示"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-table :data="enterpriseList" border style="width: 100%">
        <el-table-column prop="customerName" label="企业名称" align="center">
        </el-table-column>
        <el-table-column prop="applyDate" label="申请时间" align="center">
        </el-table-column>
        <el-table-column prop="publicDateStart" label="公示时间" align="center">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { publicEnterpriseApi } from "../../api/reserve";
export default {
  components: {},
  props: {},
  data() {
    return {
      publicEnterpriseList: [],
      enterpriseList: [],
      customerName: "",
      dialogVisible: false,
    };
  },
  watch: {},
  computed: {
    optionHover() {
      return {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    toApplyform() {
      this.$router.push({
        path: "/applyform",
      });
    },
    getPublicEnterprise() {
      publicEnterpriseApi({ customerName: this.customerName }).then((res) => {
        if (res.data.code == 200) {
          this.publicEnterpriseList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    search() {
      if (this.customerName != "") {
        publicEnterpriseApi({ customerName: this.customerName }).then((res) => {
          if (res.data.code == 200) {
            this.enterpriseList = res.data.data;
            if (res.data.data.length > 0) {
              this.dialogVisible = true;
            } else {
              this.$alert("未找到您查询的企业，请确认后重新查询", {
                confirmButtonText: "确定",
                callback: (action) => {},
              });
            }
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.$message.error("请输入企业名称");
        return;
      }
    },
  },
  created() {
    this.getPublicEnterprise();
  },
  mounted() {
    // let that = this;
    // //如果下面想要使用变量，请定义
    // that.mySwiper = new Swiper(".mySwiper", {
    //   autoplay: true, //是否自动滚动
    //   direction: "vertical",
    //   loop: true, //是否可以循环
    //   // initialSlide: 0, //初始化第几页
    //   slidesPerView: 5,
    //   spaceBetween: 20,
    // });
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 566px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.container {
  padding: 60px;
}

.container_bg {
  background: #f5f5f5;
}

.box {
  width: 1200px;
  margin: 0 auto;
}

.one_title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.two_title {
  font-size: 18px;
  color: #949494;
  text-align: center;
  margin-top: 10px;
}

.content {
  font-size: 16px;
  color: black;
  margin-top: 50px;
}

.content p {
  text-align: center;
  line-height: 27px;
}

.gs_header {
  height: 70px;
  display: flex;
  border-radius: 15px 15px 0px 0px;
  background: #055afe;
}

.gs_header div {
  width: 33.33%;
  text-align: center;
  font-weight: bold;
  color: #fff;
  line-height: 70px;
}

.swiper_box {
  height: 200px;
  background: #eff4ff;
  overflow: hidden;
}

.swiper {
  width: 100%;
  /* height: 50px; */
}

.data_list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data_item {
  width: 100%;
  height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
}

.data_item div {
  width: 33.33%;
  line-height: 20px;
}

.search_box {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eff4ff;
  padding: 40px 0;
  border-radius: 0px 0px 15px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.search_input {
  position: relative;
}

.search_title {
  font-weight: 600;
}

.search_input input {
  display: block;
  width: 685px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e9f1ff;
  border-radius: 19px;
  padding: 0 150px 0 50px;
  outline: none;
}

.search_btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 109px;
  height: 38px;
  background: #055afe;
  border-radius: 19px;
  color: #fff;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
}

.ask_title {
  display: flex;
  align-items: center;
}

.square {
  width: 9px;
  height: 9px;
  background: #055afe;
  margin-right: 9px;
}

.ask_title span {
  font-size: 22px;
  font-weight: bold;
  color: #000000;
}

.ask_box ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
}

.ask_box ul li {
  width: 47%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(5, 90, 254, 0.2);
}

.num {
  display: flex;
  align-items: center;
  width: 43px;
  height: 100%;
  padding: 6px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 26px;
  background: #055afe;
  margin-right: 20px;
}

.ask_box ul li span {
  display: block;
}

.ask_box ul li p {
  text-align: left;
}

.service {
  margin-top: 60px;
}

.service_item {
  display: flex;
  align-items: center;
}

.left_num {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 43px;
  height: 60px;
  color: #fff;
  font-weight: bold;
  font-size: 26px;
  background: #055afe;
}

.service_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
}

.service_item {
  width: 47%;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.right_service_content {
  flex: 1;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  background: rgba(242, 242, 242, 0.5);
}

.right_service_content p {
  text-align: left;
}

.right_service_content p span {
  display: block;
}

.flow_img {
  width: 100%;
}

.flow_img img {
  display: block;
  width: 100%;
}

.material_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
}

.line {
  width: 1px;
  height: 350px;
  background: rgba(0, 0, 0, 0.2);
}

.img_box {
  width: 467px;
  height: 460px;
}

.img_box img {
  display: block;
  width: 100%;
  height: 100%;
}

.material_item {
  margin: 50px 0;
}

.material_item a {
  color: #055afe;
}

.apply_btn {
  width: 189px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  background: #055afe;
  border-radius: 21px;
  font-size: 22px;
  font-weight: bold;
  color: #fffefe;
  margin: 0 auto;
  cursor: pointer;
}
</style>
